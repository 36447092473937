import React from 'react'

import * as Yup from 'yup'
import { Field, Formik, Form } from 'formik'

import { Button, CustomInput } from 'sqrl-design-system'

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

const ChangePasswordSchema = Yup.object().shape({
  newPassword: Yup.string().required('password is required'),
  newPasswordConfirmation: Yup.string()
    .required()
    .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
})

const ChangePasswordForm = () => (
  <div>
    <Formik
      initialValues={{
        newPassword: '',
        newPasswordConfirmation: ''
      }}
      validationSchema={ChangePasswordSchema}
      onSubmit={(values, { setSubmitting }) => {
        console.log(values)
        fetch('/', {
          method: 'POST',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          body: encode({ 'form-name': 'changePassword', ...values })
        })
          .then(() => {
            alert('Success!')
            setSubmitting(false)
          })
          .catch(error => alert(error))
      }}
      render={props => (
        <Form
          name="changePassword"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
        >
          <div className="field-wrapper">
            <input type="hidden" name="form-name" value="changePassword" />

            <Field
              className={
                props.touched.newPassword && props.errors.newPassword
                  ? 'is-invalid'
                  : ''
              }
              type="password"
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              value={props.values.newPassword}
              id="newPassword"
              name="newPassword"
              label="New Password"
              component={CustomInput}
            />

            <Field
              className={
                props.touched.newPasswordConfirmation &&
                props.errors.newPasswordConfirmation
                  ? 'is-invalid'
                  : ''
              }
              type="password"
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              value={props.values.newPasswordConfirmation}
              id="newPasswordConfirmation"
              name="newPasswordConfirmation"
              label="Verify New Password"
              component={CustomInput}
            />
          </div>

          <Button
            isColor={props.isValid ? 'primary' : ''}
            className="is-rounded full-width set-password"
            type="submit"
          >
            Set Password
          </Button>
        </Form>
      )}
    />
  </div>
)

export default ChangePasswordForm
