import React from 'react'
import ChangePassword from '../components/ChangePassword/ChangePassword'
import SEO from '../components/SEO'

export default () => (
  <div style={{ backgroundColor: '#f5f5f5' }}>
    <SEO title='Change Password' />
    <ChangePassword />
  </div>
)
