import React, { Component } from 'react'
import {
  FlatLogo,
  Section,
  Container,
  Card,
  CardHeader,
  CardContent
} from 'sqrl-design-system'

import ChangePasswordForm from '../forms/changePassword'

class ChangePassword extends Component {
  render() {
    return (
      <Section>
        <Container>
          <Card
            style={{ maxWidth: 600, marginLeft: 'auto', marginRight: 'auto' }}
          >
            <CardHeader className="forgot-password-header">
              <FlatLogo fill="#9013fe" style={{ marginRight: 5 }} />
              <span style={{ color: '#202733' }}>|{'  '}Workforce</span>
            </CardHeader>
            <CardContent style={{ padding: 0 }}>
              <div className="forgot-password-wrapper">
                <h1>Change Your Password</h1>
                <ChangePasswordForm />
              </div>
            </CardContent>
          </Card>
        </Container>
      </Section>
    )
  }
}

export default ChangePassword
